import React, { useEffect, useState } from 'react'
import CountUp from 'react-countup';
import './LogisticsService.scss'

const LogisticsService = ({LogisticsServiceContent, scrollPosition}) => {
    const lsObj = LogisticsServiceContent;
    const serviceStat = lsObj.serviceStat;
    const gallery = lsObj.gallery;

    const startPageY = 2500;

    return (
        <div className='logistic-service'>
            <div className={`tw-container ${scrollPosition > startPageY ? 'visible' : ''}`}>
                <div className='d-flex'>
                    <div className='ls-content'>
                        <h2 className='ls-title'>{lsObj.title}</h2>
                        <p> {lsObj.text}</p>
                        <a className={`ls-btn btn btn--split`} href={lsObj.link}><span className="main">Contact Us</span> <span className="icon-caret_right icon"></span></a>
                    </div>
                    
                    <div className='ls-number'>
                        <ul className='ls_main-numbers'>
                            {
                                serviceStat.map((val, index) => (
                                    <li key={`ls_${index}`} className='ls-numbers_item'>
                                        <div className='wrapper'>
                                            <span className='countNum h3'><CountUp end={val[1]} enableScrollSpy scrollSpyOnce/></span>
                                            <span className='suffix suffix--plus h3'>+</span>
                                        </div>
                                        <span className='label'>{val[0]}</span>
                                    </li>
                                ))
                            }
                        </ul>
                    </div>
                </div>

                <ul className="ls-gallery">
                    {
                        gallery.map((val, index) => (
                            <li className="ls-gallery_item" key={index}>
                                <a className="link">
                                    <picture>
                                        <img className="lazy" data-src={val} src={val} alt="media"/>
                                    </picture>
                                </a>
                            </li>
                        ))
                    } 
                </ul>
            </div>
        </div>
  )
}

export default LogisticsService