import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import './ProductIntro.scss'

const ProductIntro = ({ ProductIntroContent, scrollPosition}) => {
    // Screen width
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const handleResize = () => {
        setScreenWidth(window.innerWidth);
    };
    
    useEffect(() => {
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const startPageY = 300;
    const minWidth = 940;
    
    const products = ProductIntroContent.products;
    return (
        <section className="product-intro">
            <div className="tw-container">
                <h2 className={`tw-title ${scrollPosition > startPageY? 'visible' : ''}`}>Our Best-Selling Products</h2>
            </div>

            {products.map((obj, index) => (
                <div key={`containter_${index}`} className="tw-container">
                    <div className="tw-content">
                        <div className={`slide ${scrollPosition > startPageY + index * 415? 'visible' : ''}`}>
                            <div className='slide-content d-flex'>
                                {
                                    index % 2 == 0 || screenWidth <= minWidth ? (
                                        <div className="slide-content_image">
                                            <img src={obj.image} alt="" />
                                        </div>
                                    ) : ''
                                }

                                <div className="slide-content_info">
                                    <h1>{`0${index + 1}`}</h1>
                                    <h3>{obj.name}</h3>
                                    <p>{obj.text}</p>

                                    <a className="sci_btn btn btn--split fade-up visible" href={obj.link}>
                                        <span className="main">Learn more</span> <span className="icon-caret_right icon"></span>
                                    </a>
                                </div>

                                {
                                    index % 2 == 1 && screenWidth > minWidth ? (
                                        <div className="slide-content_image">
                                            <img src={obj.image} alt="" />
                                        </div>
                                    ) : ''
                                }

                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </section>
    )
}

export default ProductIntro