import React, { useEffect, useState} from 'react'

import './Home.scss';
import Hero from '../../components/Hero/Hero';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import ProductIntro from '../../components/ProductIntro/ProductIntro';
import LogisticsService from '../../components/LogisticsService/LogisticsService';
import News from '../../components/News/News';
import Partners from '../../components/Partners/Partners';
import Certifications from '../../components/Certifications/Certifications';
import ContactButton from '../../components/ContactButton/ContactButton';
import productCategories from '../../pages/CategoriesProduct/productCategories';
import ProductPage from '../Product_Page/Product_Page';

import slide1 from '../../assets/img/index/background_slide_1.jpg';
import slide2 from '../../assets/img/index/background_slide_2.jpg';
import slide3 from '../../assets/img/index/background_slide_3.jpg';



// Product
import productPic1 from '../../assets/img/index/Product 1.jpg';
import productPic2 from '../../assets/img/index/Product 2.jpg';
import productPic3 from '../../assets/img/index/Product 3.jpg';
import productPic4 from '../../assets/img/index/Product 4.jfif';

// Certificate
import certificatePic1 from '../../assets/img/index/certificate 1.jpg';
import certificatePic2 from '../../assets/img/index/certificate 2.jpg';

import pic1 from '../../assets/img/index/gallery01.webp';
import pic2 from '../../assets/img/index/gallery02.webp';
import pic3 from '../../assets/img/index/gallery03.webp';

import partner1 from '../../assets/img/index/partner1.jpg';
import partner2 from '../../assets/img/index/partner2.jpg';
import partner3 from '../../assets/img/index/partner3.jpg';
import EndContent from '../../components/EndContent/EndContent';

const Home = ({FooterContentEN}) => {

    const HeaderContentEN = {
        listCategory: [
            {
                name: "Home",
                link: "/",
                listSubItem: []
            },
            {
                name: "About Us",
                link: "/aboutus",
                listSubItem: []
            },
            {
                name: "Contact Us",
                link: "/contactus",
                listSubItem: []
            },
            {
                name: "Products",
                listSubItem: productCategories.map(category => [category.title, `/product/${category.route.toLowerCase()}`])
            }
        ]
    };
    
    const [onClickNav, setOnClickNav] = useState(false);

    const toggleActive = () => {
        setOnClickNav(!onClickNav);
        document.body.style.overflow = onClickNav ? '' : 'hidden';
    }

    // ** HERO ** //
    const HeroContentEN = {
        slides: [slide1, slide2, slide3],
        title: (
            <>
                <span>Vinmi</span> - Your Trusted Source for High Quality CaCO3 Products
            </>
        ),
        text: "With over 10+ years of experience, we are committed to providing premium quality CaCO3 products to meet the diverse needs of various industries. Our products are meticulously processed to ensure the highest standards of purity and consistency.",
        button: "View Product",
        listSubItem: productCategories.map(category => [category.title])
    };

    // ** PRODUCT ** //
    const ProductIntroContentEN = {
        products: [
            {
                // https://www.linkedin.com/pulse/caco3-powder-paints-coatings-industry-kate-duong/
                name: "CaCO3 for Paint Industry",
                text: "Calcium carbonate is a crucial additive in the paint industry, known for keeping dense pigments like lithopone suspended and preventing them from settling. It enhances the whiteness and luster of paint films without compromising coverage...",
                image: productPic1,
                link: "/product/paint"
            },
            {
                name: "CaCO3 for Plastic Industry",
                text: "Calcium carbonate is a versatile and widely utilized material in the plastic industry. It has been served as a filler to enhance the stability of plastic products. This will help plastic products have a longer life, resulting in longer functional use...",
                image: productPic2,
                link: "/product/plastic"
            },
            {
                name: "CaCO3 for Paper Industry",
                text: "Calcium Carbonate is commonly used in many types of paper as a filler and surface coating agent in Paper Industry such as Office Paper & Cigarette/ Tissue Paper for premium quality paper products. It enhances brightness, smoothness and improves opacity...",
                image: productPic3,
                link: "/product/paper"
            },
            {
                // https://ducthinhmaterials.com/en/caco3-limestone-powder-in-the-production-of-animal-feed/
                name: "CaCO3 for Poultry Feed Industry",
                text: "Calcium carbonate limestone powder is widely used and essential in animal feed production. It play a vital role by supplying minerals and adjusting pH levels, creating optimal conditions for the development and immune systems of animals...",
                image: productPic4,
                link: "/product/poultry_feed"
            }
        ]
    }

    // ** LOGISTIC SERVICE ** //
    const LogisticsServiceContentEN = {
        title: "Excellent Logistics Service",
        text: "We always have an attitude of gratitude and always treat our customers like the way we want to be treated",
        link: "/contactus",
        serviceStat: [["Countries", 20], ["Shipments Completed", 1000], ["Satisfied Clients", 3000]],
        gallery: [pic1, pic2, pic3]
    }

    // // ** NEWS ** //
    // const NewsContentEN = {
    //     news: [
    //         {
    //             title: "News title",
    //             content: "Nothing special, just a test content",
    //             uploadDate: "31/12/1999",
    //             link: "/"
    //         },
    //         {
    //             title: "News title",
    //             content: "Nothing special, just a test content",
    //             uploadDate: "31/12/1999",
    //             link: "/"
    //         },
    //         {
    //             title: "News title",
    //             content: "Nothing special, just a test content",
    //             uploadDate: "31/12/1999",
    //             link: "/"
    //         },
    //         {
    //             title: "News title",
    //             content: "Nothing special, just a test content",
    //             uploadDate: "31/12/1999",
    //             link: "/"
    //         }
    //     ]
    // }

    // ** PARTNERS ** //
    const PartnersContent = {
        partners: [partner1, partner2, partner3]
    }

    // ** CERTIFICATE ** //
    const CertificationsContent = {
        certifications: [certificatePic1, certificatePic2]
    }

    // ** END CONTENT ** //
    const EndContentContent = {
        link1: "/contactus",
        link2: "/"
    }

    // STATE

    // Scroll position control which item is display
    const [scrollPosition, setScrollPosition] = useState(0);
    const handleScroll = () => {
        setScrollPosition(window.scrollY);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <>
            <Header HeaderContent={HeaderContentEN} scrollPosition={scrollPosition} onClickNav={onClickNav} toggleActive={toggleActive}/>
            <Hero HeroContent={HeroContentEN}/>
            <ProductIntro ProductIntroContent={ProductIntroContentEN} scrollPosition={scrollPosition}/>
            <LogisticsService LogisticsServiceContent={LogisticsServiceContentEN} scrollPosition={scrollPosition}/>
            
            <div className='section-2'>
                <Partners PartnersContent={PartnersContent} scrollPosition={scrollPosition}/>
                <Certifications CertificationsContent={CertificationsContent} scrollPosition={scrollPosition} />
            </div>
            
            {/* <News NewsContent={NewsContentEN} scrollPosition={scrollPosition}/> */}
            <EndContent EndContentContent={EndContentContent} scrollPosition={scrollPosition}/>
            <Footer FooterContent={FooterContentEN}/>
            <ContactButton/>
        </>
    )
}

export default Home