import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './assets/css/libs.min.css';
import './assets/css/preload.min.css';
import './assets/css/icomoon.css';


import Home from './pages/Home/Home';
import AboutUs from './pages/AboutUs/AboutUs';
import ContactUs from './pages/ContactUs/ContactUs';
import Plastic from './pages/Product/Plastic/Plastic';
import Paint from './pages/Product/Paint/Paint';
import Paper from './pages/Product/Paper/Paper';
import Poultry_Feed from './pages/Product/Poultry_Feed/Poultry_Feed'
import ProductPage from './pages/Product_Page/Product_Page';
// Footer
import footerIconPhone from './assets/svg/phone.svg';
import footerIconMail from './assets/svg/mail.svg';
import footerIconMap from './assets/svg/map.svg';
import footerMap from './assets/img/index/map 2.png';


const App = () => {
    const FooterContentEN = {
        // aboutList element: [name, link]
        listCategory: [["About Us", "/aboutus"], ["Services", ""], ["Contacts", "/contactus"]],
        listProduct: [["Calcium Carbonate Powder", "/products"]],
        listExtra: [
            ["Contact", "/contactus"],
            ["Term of Use", ""],
            ["Privacy Policy", ""]
        ],
        listInformation: [
            [footerIconPhone, "+84 32626 5577"],
            [footerIconMail, "sales@vinmi.vn"],
            [footerIconMap, "Kuchen Building, Lenin Avenue, Vinh City, Nghe An province, Vietnam"],
            [footerIconMap, "Factory 1: Tho Son Village, Tho Hop Commune, Quy Hop District, Nghe An province, Vietnam"],
        ],
        image: footerMap
    }

    return (
        <Router>
            <Routes>
                <Route path="/" element={<Home FooterContentEN={FooterContentEN} />} />
                <Route path="/aboutus" element={<AboutUs />} />
                <Route path="/products" element={<ProductPage FooterContentEN={FooterContentEN}/>} />

                <Route path="/contactus" element={<ContactUs FooterContentEN={FooterContentEN}/>} />
                <Route path="/product/plastic" element={<Plastic FooterContentEN={FooterContentEN} />} />
                <Route path="/product/paint" element={<Paint FooterContentEN={FooterContentEN} />} />
                <Route path="/product/paper" element={<Paper FooterContentEN={FooterContentEN} />} />
                <Route path="/product/poultry_feed" element={<Poultry_Feed FooterContentEN={FooterContentEN} />} />

            </Routes>
        </Router>
    );
};


export default App;
