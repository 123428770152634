import React from 'react';
import './ProductTable.scss';

const ProductTable = () => {
  return (
    <div className="table-container">
      <table className="product-table">
        <thead>
          <tr>
            <th>COMPOUND</th>
            <th>VALUE</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Calcium Carbonate - CaCO3</td>
            <td> {'>'}98% Min </td>
          </tr>
          <tr>
            <td>Iron Oxide - Fe2O3</td>
            <td>0.015% Max</td>
          </tr>
          <tr>
            <td>Magnesium - MgO</td>
            <td>0.02% Max</td>
          </tr>
          <tr>
            <td>Silica - SiO2</td>
            <td>0.03% Max</td>
          </tr>
          <tr>
            <td>Aluminium Oxide - Al2O3</td>
            <td>0.02% Max</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default ProductTable;
