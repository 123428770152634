import React from 'react';
import './OtherProduct.scss';

const OtherProducts = ({ products }) => {
    return (
        <section className="other-products">
            <h2>Other Products</h2>
            <div className="product-grid">
                {products.map(product => (
                    <div key={product.id} className="product-item">
                        <a href={product.link}>
                            <img src={product.image} alt={product.name} className="product-image" />
                            <p>{product.name}</p>
                        </a>
                    </div>
                ))}
            </div>
        </section>
    );
};

export default OtherProducts;
