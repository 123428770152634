import React, { useEffect, useState } from 'react';
import './style.scss';

import Header from '../../../components/Header/Header';
import Footer from '../../../components/Footer/Footer';
import BackgroundSlider from '../../../components/BackgroundSlider/BackgroundSlider';
import OtherProduct from '../../../components/OtherProduct/OtherProduct';
import productCategories from '../../../pages/CategoriesProduct/productCategories';
import productImages from '../../../assets/img/product/ProductImage';
import ContactPopUp from '../../../components/ContactPopUp/ContactPopUp';

const Poultry_Feed = ({FooterContentEN}) => {
    const [scrollPosition, setScrollPosition] = useState(0);
    const [onClickNav, setOnClickNav] = useState(false);
    const [showContactForm, setShowContactForm] = useState(false);
    const [infoType, setInfoType] = useState('');
    const [productName, setProductName] = useState('Poultry feed');

    const HeaderContentEN = {
        listCategory: [
            {
                name: "Home",
                link: "/",
                listSubItem: []
            },
            {
                name: "About Us",
                link: "/aboutus",
                listSubItem: []
            },
            {
                name: "Contact Us",
                link: "/contactus",
                listSubItem: []
            },
            {
                name: "Products",
                listSubItem: productCategories.map(category => [category.title, `/product/${category.route.toLowerCase()}`])
            }
        ]
    };

    const products = [
        {
            id: 1,
            name: 'Calcium Carbonate for Paper Industry',
            image: productImages.Paper_img,
            link: '/product/paper'
        },
        {
            id: 2,
            name: 'Calcium Carbonate for Plastic Industry',
            image: productImages.Plastic_img,
            link: '/product/plastic'
        },
        {
            id: 3,
            name: 'Calcium Carbonate Powder for Paint Feed Industry',
            image: productImages.Paint_img,
            link: '/product/paint'
        }
    ];


    const toggleActive = () => {
        setOnClickNav(!onClickNav);
        document.body.style.overflow = onClickNav ? '' : 'hidden';
    };

    const handleScroll = () => {
        setScrollPosition(window.scrollY);
    };
    const handleButtonClick = (type) => {
        setInfoType(type);
        setShowContactForm(true);
    };

    const closeContactForm = () => {
        setShowContactForm(false);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    return (
        <div>
            <div className='margin-page'>
                {/* <BackgroundSlider /> */}
                <Header
                    HeaderContent={HeaderContentEN}
                    scrollPosition={scrollPosition}
                    onClickNav={onClickNav}
                    toggleActive={toggleActive}
                    customClass="custom-header-color"

                />
                <div className='product-body'>
                <h1 className='product-header'>Calcium Carbonate Powder for Poultry Feed Industry</h1>
                <div className="image-buttons-container">
                    <img src={productImages.Poultry_img} alt="Poultry Feed" className="paint-image" />

                    <div className="buttons-container">
                    <button className="action-button" onClick={() => handleButtonClick('Technical datasheet')}>Technical datasheet</button>
                    <button className="action-button" onClick={() => handleButtonClick('Download catalogue')}>Download catalogue</button>
                    </div>

                </div>
                <table>
                    <thead>
                        <tr>
                            <th rowSpan="2">PRODUCT CODE</th>
                            <th rowSpan="2">MESH SIZE</th>
                            <th colSpan="2">CHEMICAL ANALYSIS</th>
                            <th colSpan="2">PHYSICAL ANALYSIS</th>
                        </tr>
                        <tr>
                            <th className="sub-header">Compound</th>
                            <th className="sub-header">Value</th>
                            <th className="sub-header">Property</th>
                            <th className="sub-header">Value</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>VMI - 01F</td>
                            <td>250</td>
                            <td>Ca</td>
                            <td>≥ 38%</td>
                            <td>Dry Brightness</td>
                            <td>≥ 90%</td>
                        </tr>
                        <tr>
                            <td>VMI - 02F</td>
                            <td>2- 3mm</td>
                            <td>CaCO3</td>
                            <td>≥ 96%</td>
                            <td>Whiteness</td>
                            <td>≥ 90%</td>
                        </tr>
                        <tr>
                            <td>VMI - 03F</td>
                            <td>3- 4mm</td>
                            <td>CaO</td>
                            <td>≥ 54.60%</td>
                            <td>Endosmosis</td>
                            <td>~ 2,3g Alcohol</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                            <td>FE2O3</td>
                            <td>≤ 0.04%</td>
                            <td>Humidity</td>
                            <td>≤ 0.03%</td>
                        </tr><tr>
                            <td></td>
                            <td></td>
                            <td>MgO</td>
                            <td>≤ 0.91%</td>
                            <td>Content Loss On Ignition</td>
                            <td>≤ 43.8%</td>
                        </tr><tr>
                            <td></td>
                            <td></td>
                            <td>SiO2</td>
                            <td>≤ 0.23%</td>
                            <td></td>
                            <td></td>
                        </tr><tr>
                            <td></td>
                            <td></td>
                            <td>Al2O3</td>
                            <td>≤ 0.15%</td>
                            <td></td>
                            <td></td>
                        </tr><tr>
                            <td></td>
                            <td></td>
                            <td>K2O</td>
                            <td>≤ 0.05%</td>
                            <td></td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
                <section className="applications">
                    <h2>Typical Applications</h2>
                    <p>
                        Calcium carbonate powder with application for all animal and poultry feed products is a calcium content supplement and mineral supplement for livestock. Calcium carbonate is essential for egg formation and bone growth.               </p>
                </section>
                <OtherProduct products={products} />
                </div>
            </div>

            <Footer FooterContent={FooterContentEN} />
            {showContactForm && (
                <div className="contact-form1-overlay" onClick={closeContactForm}>
                    <div className="contact-form1-container" onClick={(e) => e.stopPropagation()}>
                        <ContactPopUp infoType={infoType} productName={productName} onClose={closeContactForm} />
                    </div>
                </div>
            )}
        </div>
    );
};

export default Poultry_Feed;
