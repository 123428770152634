import React from 'react'
import './ContactButton.scss'

const ContactButton = () => {
  return (
    <a className='contact_btn' href='https://wa.me/84326265577' target='_blank'>
        <div className='animated infinite zoomIn mypage-alo-ph-circle'></div>
        <div className='animated infinite pulse mypage-alo-ph-circle-fill'></div>
        <div className='animated infinite tada mypage-alo-ph-img-circle'></div>
    </a>
  )
}

export default ContactButton