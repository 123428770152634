import React, { useState, useEffect, useRef } from 'react'
import './Certifications.scss'

const Certifications = ({CertificationsContent, scrollPosition}) => {
    const certifications = CertificationsContent.certifications;
    const startPageY = 3800;

    return (
        <section className="certifications">
            <div className="tw-container">
                <div className='certifications-content'>
                    <h2 className={`certifications-title ${scrollPosition > startPageY ? 'visible' : ''}`}>Our certifications</h2>
                    <div className={`certifications-list_post_view ${scrollPosition > startPageY ? 'visible' : ''}`}>
                        <div className='certifications-list_post'>
                            {
                                Array.from({ length: certifications.length * 2 }).map((_, repeatIndex) => (
                                    certifications.map((val, index) => (
                                        <div key={`partner_${repeatIndex}.${index}`} className='certifications-item'>
                                            <img src={val} />
                                        </div>
                                    ))
                                )).flat()
                            }
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Certifications