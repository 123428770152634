import React from 'react'
import "./EndContent.scss"

const EndContent = ({EndContentContent, scrollPosition}) => {
    const startPageY = 4300;
    return (
        <section className={`end-content ${scrollPosition > startPageY ? 'visible' : ''}`}>
            <h2 className={`end-content_title`}>Want to see our product</h2>
            <div className={`nav`}>
                <a href={EndContentContent.link1}>Contact us</a>
            </div>
        </section>
    )
}

export default EndContent