import React, { useEffect, useState } from 'react';
import slide1 from '../../assets/img/index/background_slide_1.jpg';
import slide2 from '../../assets/img/index/background_slide_2.jpg';
import slide3 from '../../assets/img/index/background_slide_3.jpg';
import './BackgroundSlider.scss';

const images = [slide1, slide2, slide3];

const BackgroundSlider = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 10000); 
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="background-slider">
      {images.map((image, index) => (
        <div
          key={index}
          className={`slide ${index === currentIndex ? 'active' : ''}`}
          style={{ backgroundImage: `url(${image})` }}
        />
      ))}
    </div>
  );
};

export default BackgroundSlider;
