import anime from "animejs";
import './AboutUsHero.scss';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const WaterDropGrid = () => {
  const [blurVisible, setBlurVisible] = useState(true); // State to manage blur visibility

  useEffect(() => {
    anime({
      targets: '.text-container',
      opacity: [0, 1],
      translateY: [-20, 0],
      easing: 'easeOutExpo',
      duration: 1000,
      delay: 500,
    });
  }, []);

  const navigate = useNavigate();

  const handleBtnClick = () => {
    navigate('/contactus');
  };

  const handleScreenClick = () => {
    setBlurVisible(false); // Set blur to disappear on click
  };

  return (
    <div
      className={`relative flex items-center justify-center bg-slate-900 px-8 py-12 ${blurVisible ? 'blur-on' : 'blur-off'}`}
      onClick={handleScreenClick} // Handle screen click to toggle blur
    >
      <DotGrid />
      <div className="absolute z-10 text-container text-left">
        <h1 className="h1-text">
          WE ARE <span className="highlight">VINMI</span>.
        </h1>
        <h2>
          Email: <span className="highlight">sales@vinmi.vn</span>
        </h2>
        <p>Address: Kuchen Building, Lenin Avenue, Vinh City, Nghe An Province, Vietnam</p>
        <p>Tel: +84 32626 5577</p>
        <button className="contact-button" onClick={handleBtnClick}>Contact Us</button>
      </div>
    </div>
  );
};

const DotGrid = () => {
  const handleDotClick = (e) => {
    anime({
      targets: ".dot-point",
      scale: [
        { value: 1.35, easing: "easeOutSine", duration: 250 },
        { value: 1, easing: "easeInOutQuad", duration: 500 },
      ],
      translateY: [
        { value: -15, easing: "easeOutSine", duration: 250 },
        { value: 0, easing: "easeInOutQuad", duration: 500 },
      ],
      opacity: [
        { value: 1, easing: "easeOutSine", duration: 250 },
        { value: 0.5, easing: "easeInOutQuad", duration: 500 },
      ],
      delay: anime.stagger(100, {
        grid: [25, 20],
        from: e.target.dataset.index,
      }),
    });
  };

  const dots = [];
  let index = 0;
  for (let i = 0; i < 25; i++) {
    for (let j = 0; j < 20; j++) {
      dots.push(
        <div
          className="group cursor-crosshair rounded-full p-2 transition-colors hover:bg-slate-600"
          data-index={index}
          key={`${i}-${j}`}
        >
          <div className="dot-point h-2 w-2 rounded-full bg-gradient-to-b from-slate-700 to-slate-400 opacity-50 group-hover:from-indigo-600 group-hover:to-white" data-index={index} />
        </div>
      );
      index++;
    }
  }

  return (
    <div
      onClick={handleDotClick}
      style={{ gridTemplateColumns: `repeat(25, 1fr)` }}
      className="grid w-fit absolute z-0"
    >
      {dots}
    </div>
  );
};

export default WaterDropGrid;
