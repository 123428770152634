import React, { useState, useEffect, useRef } from 'react'
import './Partners.scss'

const Partners = ({PartnersContent, scrollPosition}) => {
    const partners = PartnersContent.partners;
    const startPageY = 3500;

    return (
        <section className="partners">
            <div className="tw-container">
                <div className='partners-content'>
                    <h2 className={`partners-title ${scrollPosition > startPageY ? 'visible' : ''}`}>Our Partners</h2>
                    <div className={`partners-list_post_view ${scrollPosition > startPageY ? 'visible' : ''}`}>
                        <div className='partners-list_post'>
                            {
                                Array.from({ length: 2 }).map((_, repeatIndex) => (
                                    partners.map((val, index) => (
                                        <div key={`partner_${repeatIndex}.${index}`} className='partners-item'>
                                            <img src={val} />
                                        </div>
                                    ))
                                )).flat()
                            }
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Partners