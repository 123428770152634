    import React, { useState } from 'react';
    import './ContactPopUp.scss';
    import emailjs from 'emailjs-com';

    const ContactPopUp = ({ infoType, productName, onClose }) => {
        const [formData, setFormData] = useState({
            name: '',
            email: '',
            subject: `Request information on ${infoType} for ${productName}`,
            message: ''
        });

        const handleChange = (e) => {
            const { name, value } = e.target;
            setFormData({ ...formData, [name]: value });
        };

        const handleSubmit = (e) => {
            e.preventDefault();

            const templateParams = {
                name: formData.name,
                email: formData.email,
                subject: formData.subject,
                message: formData.message,
            };

            emailjs.send('service_w1cl9s2', 'template_z0q25tj', templateParams, '9IxRFZsSoE_0uDPid')
                .then((response) => {
                    alert('Message sent successfully!');
                    onClose();  // Close the form after successful submission
                })
                .catch((err) => {
                    alert('Failed to send message. Please try again.');
                });
        };

        return (
            <div className="contact-form1-overlay">
                <div className="contact-form1-container">
                    <h2>Contact Us</h2>
                    <form onSubmit={handleSubmit}>
                        <label>
                            Name:
                            <input
                                type="text"
                                name="name"
                                value={formData.name}
                                onChange={handleChange}
                                required
                            />
                        </label>
                        <label>
                            Email:
                            <input
                                type="email"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                required
                            />
                        </label>
                        <label>
                            Subject:
                            <input
                                type="text"
                                name="subject"
                                value={formData.subject}
                                readOnly
                            />
                        </label>
                        <label>
                            Message:
                            <textarea
                                name="message"
                                value={formData.message}
                                onChange={handleChange}
                                required
                            />
                        </label>
                        <div className="form-actions">
                            <button type="submit">Submit</button>
                            <button type="button" onClick={onClose}>Cancel</button>
                        </div>
                    </form>
                </div>
            </div>
        );
    };

    export default ContactPopUp;
