const productCategories = [
    { title: "Calcium Carbonate for Plastic Industry",
      route: "plastic"
     },
    { title: "Calcium Carbonate for Paint Industry",
     route: "paint"
     },
    { title: "Calcium Carbonate for Paper Industry",
        route: "paper"
     },
    { title: "Calcium Carbonate Powder for Poultry Feed Industry",
        route:"poultry_feed"
     }
];

export default productCategories;
