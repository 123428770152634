import React, { useEffect } from 'react';
import './ContactForm.scss';
import '@fortawesome/fontawesome-free/css/all.min.css';
import BackgroundSlider from '../../components/BackgroundSlider/BackgroundSlider';
import emailjs from 'emailjs-com';

import contactImage from '../../assets/img/contact-us/img.png';

const ContactForm = () => {
  useEffect(() => {
    const contactInfo = document.querySelector('.contact-info');
    const contactFormContainer = document.querySelector('.contact-form-container');

    // Add 'visible' class to trigger the animation when the component mounts
    contactInfo.classList.add('visible');
    contactFormContainer.classList.add('visible');
  }, []);

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_w1cl9s2', 'template_z0q25tj', e.target, '9IxRFZsSoE_0uDPid')
      .then((result) => {
        console.log(result.text);
        alert('Email sent successfully!');
      }, (error) => {
        console.log(error.text);
        alert('Failed to send email.');
      });

    e.target.reset();  // Optional: Reset the form after submission
  };

  return (
    <div className="contact-container">
      <BackgroundSlider />
      <div className="contact-info">
        <div className="info-item">
          <div className="icon-circle">
            <i className="fas fa-map-marker-alt"></i>
          </div>
          <div>
            <h4>Address:</h4>
            <p>Kuchen Buiding, Lenin Avenue, Vinh City, Nghe An Province, Vietnam</p>
          </div>
        </div>
        <div className="info-item">
          <div className="icon-circle">
            <i className="fas fa-phone"></i>
          </div>
          <div>
            <h4>Phone:</h4>
            <p>+84 32626 5577</p>
          </div>
        </div>
        <div className="info-item">
          <div className="icon-circle">
            <i className="fas fa-envelope"></i>
          </div>
          <div>
            <h4>Email:</h4>
            <p>sales@vinmi.vn</p>
          </div>
        </div>
        <div className="info-item">
          <div className="icon-circle">
            <i className="fas fa-globe"></i>
          </div>
          <div>
            <h4>Website:</h4>
            <p>vinmi.vn</p>
          </div>
        </div>
      </div>
      <div className="contact-form-container">
        <div className="contact-form">
          <h1>Contact Us</h1>
          <form onSubmit={sendEmail}>
            <div className="form-group">
              <label>Full Name</label>
              <input type="text" name="name" placeholder="Name" required />
            </div>
            <div className="form-group">
              <label>Email Address</label>
              <input type="email" name="email" placeholder="Email" required />
            </div>
            <div className="form-group">
              <label>Subject</label>
              <input type="text" name="subject" placeholder="Subject" required />
            </div>
            <div className="form-group">
              <label>Message</label>
              <textarea name="message" placeholder="Message" required></textarea>
            </div>
            <button type="submit">Send Message</button>
          </form>
        </div>
        <div className="contact-image">
          <img src={contactImage} alt="Contact Image" />
        </div>
      </div>
    </div>
  );
};

export default ContactForm;
