import React, { useEffect, useState } from 'react';
import './Hero.scss';

const Hero = ({HeroContent}) => {
    // Slider
    const [slideIndex, setSlideIndex] = useState(-1);
    const slides = HeroContent.slides;
    const maxSlide = slides.length;

    // Container content
    const [isVisible, setIsVisible] = useState(false);

    // Start up
    useEffect(() => {
        setTimeout(() => setSlideIndex(0), 200);
        setTimeout(() => {setIsVisible(true)}, 100);
    }, [])

    useEffect(() => { 
        const timeoutId = setTimeout(
            () => {
                setSlideIndex((prevIndex) => prevIndex === maxSlide - 1 ? 0 : prevIndex + 1); 
            }, 5000
        );

        return () => clearTimeout(timeoutId);
    }, [slideIndex]);

    return (
        <div className="slider">
            <div className={`slider-overlay ${!isVisible? 'remove-background': ''}`} onClick={() => {setIsVisible(!isVisible)}}></div>
            <div className="slider-media swiper">               
                <div className="swiper-wrapper" style={{ transform: `translate3d(${-slideIndex * 100}%, 0, 0)`, transition: `ease 1000ms`}}>
                    {slides.map((slide, idx) => (
                        <div className={`slider-media-slide swiper-slide ${idx === slideIndex ? 'swiper-slide-active' : ''}`} key={idx} style={{zIndex: idx === slideIndex? '2' : '0'}}>
                            <picture>
                                <img className="lazy" src={slide} alt="media" data-transform-origin="center center"/>   
                            </picture>
                        </div>
                    ))}
                </div>
            </div>
            
            <div className="container " onClick={() => {setIsVisible(!isVisible)}}>
                <div className="slider-content">
                    <h1 className={`slider-content_title fade-up ${isVisible? 'visible': ''}`}>{HeroContent.title}</h1>
                    <p className={`slider-content_text fade-up ${isVisible? 'visible': ''}`}>{HeroContent.text}</p>
                    <a className={`slider-content_btn btn btn--split fade-up ${isVisible? 'visible': ''}`} href="/products"><span className="main">{HeroContent.button}</span> <span
                            className="icon-caret_right icon"></span></a>
                </div>
            </div>
        </div>
    )
}

export default Hero;
