import React, { useEffect, useState } from 'react';
import './style.scss';

import Header from '../../../components/Header/Header';
import Footer from '../../../components/Footer/Footer';
import BackgroundSlider from '../../../components/BackgroundSlider/BackgroundSlider';
import OtherProduct from '../../../components/OtherProduct/OtherProduct';
import productCategories from '../../../pages/CategoriesProduct/productCategories';
import productImages from '../../../assets/img/product/ProductImage';
import ContactPopUp from '../../../components/ContactPopUp/ContactPopUp';


const Plastic = ({FooterContentEN}) => {
    const [scrollPosition, setScrollPosition] = useState(0);
    const [onClickNav, setOnClickNav] = useState(false);
    const [showContactForm, setShowContactForm] = useState(false);
    const [infoType, setInfoType] = useState('');
    const [productName, setProductName] = useState('Plastic');
    const HeaderContentEN = {
        listCategory: [
            {
                name: "Home",
                link: "/",
                listSubItem: []
            },
            {
                name: "About Us",
                link: "/aboutus",
                listSubItem: []
            },
            {
                name: "Contact Us",
                link: "/contactus",
                listSubItem: []
            },
            {
                name: "Products",
                listSubItem: productCategories.map(category => [category.title, `/product/${category.route.toLowerCase()}`])
            }
        ]
    };

    const products = [
        {
            id: 1,
            name: 'Calcium Carbonate for Paper Industry',
            image: productImages.Paper_img, 
            link: '/product/paper'
        },
        {
            id: 2,
            name: 'Calcium Carbonate for Paint Industry',
            image: productImages.Paint_img,
            link: '/product/paint'
        },
        {
            id: 3,
            name: 'Calcium Carbonate Powder for Poultry Feed Industry',
            image: productImages.Poultry_img,
            link: '/product/poultry_feed'
        }
    ];


    const toggleActive = () => {
        setOnClickNav(!onClickNav);
        document.body.style.overflow = onClickNav ? '' : 'hidden';
    };

    const handleScroll = () => {
        setScrollPosition(window.scrollY);
    };
    const handleButtonClick = (type) => {
        setInfoType(type);
        setShowContactForm(true);
    };

    const closeContactForm = () => {
        setShowContactForm(false);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div>
            <div className='margin-page'>
            {/* <BackgroundSlider /> */}
            <Header
                HeaderContent={HeaderContentEN}
                scrollPosition={scrollPosition}
                onClickNav={onClickNav}
                toggleActive={toggleActive}
                customClass="custom-header-color"

                
            />
            <div className='product-body'>
            <h1 className='product-header'>Calcium Carbonate for Plastic Industry</h1>
            <div className="image-buttons-container">
            <img src={productImages.Plastic_img} alt="Plastic" className="paint-image" />

                <div className="buttons-container">
                <button className="action-button" onClick={() => handleButtonClick('Technical datasheet')}>Technical datasheet</button>
                <button className="action-button" onClick={() => handleButtonClick('Download catalogue')}>Download catalogue</button>
                </div>

            </div>
            <table>
                <thead>
                    <tr>
                        <th rowSpan="2">PRODUCT CODE</th>
                        <th rowSpan="2">MESH SIZE</th>
                        <th colSpan="2">CHEMICAL ANALYSIS</th>
                        <th colSpan="2">PHYSICAL ANALYSIS</th>
                    </tr>
                    <tr>
                        <th className="sub-header">Compound</th>
                        <th className="sub-header">Value</th>
                        <th className="sub-header">Property</th>
                        <th className="sub-header">Value</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>VMI - 08/08C</td>   
                        <td>1875</td>
                        <td>CaCO3</td>
                        <td>≥ 98%</td>
                        <td>pH Value</td>
                        <td>8-9</td>
                    </tr>
                    <tr>
                        <td>VMI - 10/10C</td>
                        <td>1500</td>
                        <td>MgO</td>
                        <td>0.02% max</td>
                        <td>Moisture</td>
                        <td>0.2% max</td>
                    </tr>
                    <tr>
                        <td>VMI - 15/15C</td>
                        <td>1000</td>
                        <td>SiO2</td>
                        <td>0.03% max</td>
                        <td>Brightness</td>
                        <td>97% min</td>
                    </tr>
                    <tr>
                        <td>VMI - 20/20C</td>
                        <td>750</td>
                        <td>Al2O3</td>
                        <td>0.02% max</td>
                        <td>Whiteness</td>
                        <td>97% min</td>
                    </tr>
                    <tr>
                        <td>VMI - 25/25C</td>
                        <td>600</td>
                        <td>Fe2O3</td>
                        <td>0.015% max</td>
                        <td>DOP Oil Absorption</td>
                        <td>24g/100g CaCO3 max</td>
                    </tr>
                    <tr>
                        <td></td>
                        <td></td>
                        <td>Specific Gravity</td>
                        <td>2.7g/cm3</td>
                        <td>Bulk Density</td>
                        <td>0.42g/cm</td>
                    </tr>
                    <tr>
                        <td></td>
                        <td></td>
                        <td>Hardness</td>
                        <td>3 Moh</td>
                        <td></td>
                        <td></td>
                    </tr>
                </tbody>
                <p style={{ backgroundColor: '#ffffff' }}>
  Coated Stearic acid ratio (If coated Calcium Carbonate): 1%
</p>

            </table>

            <section className="applications">
                <h2>Typical Applications</h2>
                <ul>
                    <li>ABS</li>
                    <li>Adhesive</li>
                    <li>Asphalt</li>
                    <li>Bitumen</li>
                    <li>Casting Resin</li>
                    <li>EVA</li>
                    <li>Polymer Color</li>
                    <li>PP/ PE/ Masterbatch</li>
                    <li>uPVC pipes/ Profile</li>
                    <li>PVC Cables</li>
                    <li>PVC Sheeting</li>
                </ul>
                <p>
                    Calcium carbonate can play a role in the skeleton of plastic products. It can keep the stability of the plastic product shape. It can also increase the hardness and improve the surface gloss and surface smoothness of the plastic products. If the calcium carbonate whiteness is above 90%, it can replace the expensive white pigment.
                </p>
            </section>
            <OtherProduct products={products} />
            </div>
            </div>
            <Footer FooterContent={FooterContentEN} />
            {showContactForm && (
                <div className="contact-form1-overlay" onClick={closeContactForm}>
                    <div className="contact-form1-container" onClick={(e) => e.stopPropagation()}>
                        <ContactPopUp infoType={infoType} productName={productName} onClose={closeContactForm} />
                    </div>
                </div>
            )}
        </div>
    );
};

export default Plastic;
