import React, { useEffect, useState } from 'react';
import ProductTable from '../../components/ProductTable/ProductTable';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import productImages from '../../assets/img/product/ProductImage';
import productCategories from '../../pages/CategoriesProduct/productCategories';
import { Link } from 'react-router-dom';


import './Product_Page.scss'
const ProductPage = ({FooterContentEN}) => {
    const [onClickNav, setOnClickNav] = useState(false);
    const [scrollPosition, setScrollPosition] = useState(0);

    const HeaderContentEN = {
        listCategory: [
            {
                name: "Home",
                link: "/",
                listSubItem: []
            },
            {
                name: "About Us",
                link: "/aboutus",
                listSubItem: []
            },
            {
                name: "Contact Us",
                link: "/contactus",
                listSubItem: []
            },
            {
                name: "Products",
                listSubItem: productCategories.map(category => [category.title, `/product/${category.route.toLowerCase()}`])
            }
        ]
    };
    const toggleActive = () => {
        setOnClickNav(!onClickNav);
        document.body.style.overflow = onClickNav ? '' : 'hidden';
    };

    const handleScroll = () => {
        setScrollPosition(window.scrollY);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const products = [
        { id: 1, name: 'Calcium Carbonate for Plastic Industry', image: productImages.Plastic_img, route: 'plastic' },
        { id: 2, name: 'Calcium Carbonate for Paint Industry', image: productImages.Paint_img, route:'paint' },
        { id: 3, name: 'Calcium Carbonate for Paper Industry', image: productImages.Paper_img, route: 'paper'},
        { id: 4, name: 'Calcium Carbonate Powder for Poultry Feed Industry', image: productImages.Poultry_img, route: 'poultry_feed'}
    ];

  return (
    <div className="product-page">
        <Header
                HeaderContent={HeaderContentEN}
                scrollPosition={scrollPosition}
                onClickNav={onClickNav}
                toggleActive={toggleActive}
                customClass="custom-header-color"
            />
            <div className="product-body">
                <h6>We supply pulverized CaCO3 for applications in Plastics, Paints, Rubbers, Papers, etc. Chemical specifications are as under:</h6>
            <ProductTable />
            <div className="product-list">
                    <h6>VIN is dedicated to providing services for CaCO3 powder to global industries:
                    </h6>
                    <div className="product-list-container">
                        {products.map(product => (
                            <div key={product.id} className="product-item-page">
                                <Link to={`/product/${product.route}`}>
                                    <img src={product.image} alt={product.name} className="product-image" />
                                    <p className="product-name">{product.name}</p>
                                </Link>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
      <Footer FooterContent={FooterContentEN} />
    </div>
  );
};

export default ProductPage;
