import React, { useEffect, useState } from 'react';
import './style.scss';
import Header from '../../../components/Header/Header';
import Footer from '../../../components/Footer/Footer';
import OtherProduct from '../../../components/OtherProduct/OtherProduct';
import productImages from '../../../assets/img/product/ProductImage';
import ContactPopUp from '../../../components/ContactPopUp/ContactPopUp';
import productCategories from '../../CategoriesProduct/productCategories';
const Paint = ({ FooterContentEN }) => {
    const [scrollPosition, setScrollPosition] = useState(0);
    const [onClickNav, setOnClickNav] = useState(false);
    const [showContactForm, setShowContactForm] = useState(false);
    const [infoType, setInfoType] = useState('');
    const [productName, setProductName] = useState('Paint');

    const HeaderContentEN = {
        listCategory: [
            { name: "Home", link: "/", listSubItem: [] },
            { name: "About Us", link: "/aboutus", listSubItem: [] },
            { name: "Contact Us", link: "/contactus", listSubItem: [] },
            { name: "Products", listSubItem: productCategories.map(category => [category.title, `/product/${category.route.toLowerCase()}`]) }
        ]
    };

    const products = [
        { id: 1, name: 'Calcium Carbonate for Paper Industry', image: productImages.Paper_img, link: '/product/paper' },
        { id: 2, name: 'Calcium Carbonate for Plastic Industry', image: productImages.Plastic_img, link: '/product/plastic' },
        { id: 3, name: 'Calcium Carbonate Powder for Poultry Feed Industry', image: productImages.Poultry_img, link: '/product/poultry_feed' }
    ];

    const toggleActive = () => {
        setOnClickNav(!onClickNav);
        document.body.style.overflow = onClickNav ? '' : 'hidden';
    };

    const handleScroll = () => {
        setScrollPosition(window.scrollY);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleButtonClick = (type) => {
        setInfoType(type);
        setShowContactForm(true);
    };

    const closeContactForm = () => {
        setShowContactForm(false);
    };

    return (
        <div>
            <div className='margin-page'>
                <Header
                    HeaderContent={HeaderContentEN}
                    scrollPosition={scrollPosition}
                    onClickNav={onClickNav}
                    toggleActive={toggleActive}
                    customClass="custom-header-color"
                />
                <div className='product-body'>
                    <h1 className='product-header'>Calcium Carbonate for Paint Industry</h1>
                    <div className="image-buttons-container">
                        <img src={productImages.Paint_img} alt="Paint" className="paintt-image" />
                        <div className="buttons-container">
                            <button className="action-button" onClick={() => handleButtonClick('Technical datasheet')}>Technical datasheet</button>
                            <button className="action-button" onClick={() => handleButtonClick('Download catalogue')}>Download catalogue</button>
                        </div>
                    </div>
                    <table>
                        <thead>
                            <tr>
                                <th rowSpan="2">PRODUCT CODE</th>
                                <th rowSpan="2">MESH SIZE</th>
                                <th colSpan="2">CHEMICAL ANALYSIS</th>
                                <th colSpan="2">PHYSICAL ANALYSIS</th>
                            </tr>
                            <tr>
                                <th className="sub-header">Compound</th>
                                <th className="sub-header">Value</th>
                                <th className="sub-header">Property</th>
                                <th className="sub-header">Value</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>VMI - 08</td>   
                                <td>1875</td>
                                <td>CaCO3</td>
                                <td>≥ 98%</td>
                                <td>pH Value</td>
                                <td>8-9</td>
                            </tr>
                            <tr>
                                <td>VMI - 15</td>
                                <td>1000</td>
                                <td>MgO</td>
                                <td>0.02% max</td>
                                <td>Moisture</td>
                                <td>0.2% max</td>
                            </tr>
                            <tr>
                                <td>VMI - 20</td>
                                <td>750</td>
                                <td>SiO2</td>
                                <td>0.03% max</td>
                                <td>Brightness</td>
                                <td>97% min</td>
                            </tr>
                            <tr>
                                <td>VMI - 25</td>
                                <td>600</td>
                                <td>Al2O3</td>
                                <td>0.02% max</td>
                                <td>Whiteness</td>
                                <td>97% min</td>
                            </tr>
                            <tr>
                                <td>VMI - 3P</td>
                                <td>325</td>
                                <td>Fe2O3</td>
                                <td>0.015% max</td>
                                <td>
                                    DOP Oil Absorption <br />
                                </td>
                                <td>
                                    24g/100g CaCO3 max <br />
                                </td>
                            </tr>
                            <tr>
                                <td></td>
                                <td></td>
                                <td>Specific Gravity</td>
                                <td>2.7g/cm3</td>
                                <td>Bulk Density</td>
                                <td>0.42g/cm</td>
                            </tr>
                            <tr>
                                <td></td>
                                <td></td>
                                <td>Hardness</td>
                                <td>3 Moh</td>
                                <td></td>
                                <td></td>
                            </tr>
                        </tbody>
                    </table>
                    <section className="applications">
                        <h2>Typical Applications</h2>
                        <ul>
                            <li>Emulsion Paints</li>
                            <li>Floor Coating</li>
                            <li>Oil Paints</li>
                            <li>Palm Oil Mills</li>
                            <li>Rust-resistant Paints</li>
                            <li>Texture Paints</li>
                            <li>Thermo Paint</li>
                        </ul>
                        <p>
                            Calcium carbonate in the water-based paint industry is more widely used. It can make the paint does not settle, easy to disperse, good gloss and other characteristics.
                        </p>
                    </section>
                    <OtherProduct products={products} />
                </div>
            </div>
            <Footer FooterContent={FooterContentEN} />
            {showContactForm && (
                <div className="contact-form1-overlay" onClick={closeContactForm}>
                    <div className="contact-form1-container" onClick={(e) => e.stopPropagation()}>
                        <ContactPopUp infoType={infoType} productName={productName} onClose={closeContactForm} />
                    </div>
                </div>
            )}
        </div>
    );
};

export default Paint;
