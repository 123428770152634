import React from 'react';
import background from '../../assets/img/about-us/background.jpg';
import './OurObjectives.scss';

const OurObjectives = () => {
  return (
    <div className="section_04 our-teams-common" id="teams" style={{ backgroundImage: `url(${background})` }}>
      <div className="tw-container">
        <div className="tw-content">
          <div className="_item d-flex flex-direction-column">
            <div className="__left">
              <h2 className="h2-text">Our Team</h2>
            </div>
            <div className="__right">
              <div className="slide-list-team">
                <div className="swiper">
                  <div className="swiper-wrapper" style={{ display: 'flex', overflowX: 'auto', scrollSnapType: 'x mandatory' }}>
                    <div className="swiper-slide" style={{ flex: '0 0 auto', width: '300px', height: '600px', marginRight: '30px', scrollSnapAlign: 'start', backgroundColor: 'white', padding: '20px', borderRadius: '8px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                      <div className="__item">
                        <img className="icon_main" src="https://www.vinai.io/wp-content/uploads/2021/11/about-team1.svg" alt="Research" />
                        <p className="tw-title" style={{ fontSize: '20px', fontWeight: 'bold', color: '#333', marginTop: '45px', textShadow: '1px 1px 2px rgba(0, 0, 0, 0.2)' }}>Vision</p>
                        <p className="--txt">With long term commitment combining modern advanced technology, Vin Minerals Jsc aims to be a leading manufacturer of calcium carbonate powder in Vietnam
                        </p>
                      </div>
                    </div>
                    <div className="swiper-slide" style={{ flex: '0 0 auto', width: '300px', height: '600px', marginRight: '30px', scrollSnapAlign: 'start', backgroundColor: 'white', padding: '20px', borderRadius: '8px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                      <div className="__item">
                        <img className="icon_main" src="https://www.vinai.io/wp-content/uploads/2021/11/about-team2.svg" alt="AI Products" />
                        <p className="tw-title" style={{ fontSize: '20px', fontWeight: 'bold', color: '#333', marginTop: '15px', textShadow: '1px 1px 2px rgba(0, 0, 0, 0.2)' }}>Mission</p>
                        <p className="--txt"><strong>For customers:</strong> To supply international and regional standards of Calcium Carbonate products, meeting all technological requirements of customers with best prices and services.</p>
                       </div>
                    </div>
                    <div className="swiper-slide" style={{ flex: '0 0 auto', width: '300px', height: '600px', marginRight: '30px', scrollSnapAlign: 'start', backgroundColor: 'white', padding: '20px', borderRadius: '8px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                      <div className="__item">
                        <img className="icon_main" src="https://www.vinai.io/wp-content/uploads/2021/11/about-team3.svg" alt="Operations" />
                        <p className="tw-title" style={{ fontSize: '20px', fontWeight: 'bold', color: '#333', marginTop: '15px', textShadow: '1px 1px 2px rgba(0, 0, 0, 0.2)' }}>Commitment</p>
                        <p className="--txt">Best products, best price, fast delivery, best relationship.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OurObjectives;
