import React from 'react';
import './Footer.scss';

const Footer = ({FooterContent}) => {
    const text = FooterContent.text;
    const listCategory = FooterContent.listCategory;
    const listProduct = FooterContent.listProduct;
    const listInformation = FooterContent.listInformation;
    const listExtra = FooterContent.listExtra;

    return (
        <footer className="footer">
            <div className="footer-container">

                <div className="footer_main">
                    <div className='map-contact'>
                        <div className='footer_main_map'>
                            <img src={FooterContent.image}/>
                        </div>
                        
                        <div className="footer_main-block footer_main-block--nav">
                            <ul className="list d-flex flex-column">
                            <li><h5 className='title'>Contacts</h5></li>
                                {listInformation.map((value, index) => (
                                    <li key={`info_${index}`} className="list-item">
                                        <img className="icon_svg" src={value[0]}/> {value[1]}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>

                    

                    <div className='footer_link'>
                        <div className="footer_main-block footer_main-block--nav">
                            <ul className="list d-flex flex-column">
                                <li><h5 className='title'>Products</h5></li>
                                {listProduct.map((value, index) => (
                                    <li key={`about_${index}`} className="list-item">
                                        <i className="icon-caret_right icon"></i>
                                        <a className="link" href={value[1]}>{value[0]}</a>
                                    </li>
                                ))}
                            </ul>
                        </div>

                        <div className="footer_main-block footer_main-block--nav" style={{display: "block"}}>
                            <ul className="list d-flex flex-column">
                            <li><h5 className='title'>Sitemap</h5></li>
                                {listCategory.map((value, index) => (
                                    <li key={`other_${index}`} className="list-item">
                                        <i className="icon-caret_right icon"></i>
                                        <a className="link" href={value[1]}>{value[0]}</a>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>

                    

                    
                </div>

                <div className='footer_extra_section'>
                    {listExtra.map((value, index) => (
                        <a key={`extra_${index}`} href={value[1]}>{value[0]}</a>
                    ))}
                </div>
            </div>
        </footer>
    )
}

export default Footer