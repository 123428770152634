import Paint_img from './Paint.png';
import Paper_img from './Paper.png';
import Plastic_img from './Plastic.png';
import Poultry_img from './Poultry.png';

const productImages = {
  Paint_img, Paper_img, Plastic_img, Poultry_img
};

export default productImages;
