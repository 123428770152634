import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import './WhoWeAre.scss'
import value_img1 from '../../assets/img/about-us/value.jpg' 
import value_img2 from '../../assets/img/about-us/value_2.jpg' 
import value_img3 from '../../assets/img/about-us/value_3.jpg' 

const WhoWeAre = () => {
    
  const ImpactfulAIProductsSection = ({ title, description, imageUrl }) => {
    return (
        <section className="cards">
            <div className="container">
                <div className="row">
                    <div className="col-img">
                        <img src={imageUrl} className="img-fluid" alt={title} />
                    </div>
                    <div className="col-des d-flex flex-column justify-content-center">
                        <h2 className="title-impact">{title}</h2>
                        <div
                            className="des-impact"
                            dangerouslySetInnerHTML={{ __html: description }}
                        />
                    </div>
                </div>
            </div>
        </section>
    );
};

const [sections, setSections] = useState([
    {
        title: 'Enterprise honor',
        description: `Billions of people come into contact with VIN Minerals to cover their basic needs for varied grades (rubber, paints, plastic, paper, pharma, food, etc.) every day. For this, we develop the best process solutions along complete value chains. Many people satisfied each day when using Calcium Carbonate produced by us. Having this global relevance, we are in a unique position to tur  n today’s global challenges into sustainable and good business.`,
        imageUrl: value_img1,
    },
    {
        title: 'Production equipment',
        description: `We own one of the finest limestone quarries of Vietnam, which is located in Quy Hop, Nghe An Province. In addition, we use the most advanced technology imported from Germany and the latest laboratory equipment such as: the Whiteness Analyzer MINOLTA CR-200/ CR-210 made in Japan and Particle diffraction MALVERN made in England. We  use the latest Roller Mill Technology that is known for its high grinding efficiency. With the annual capacity of 240,000 MT. VIN always guarantees a stable supply of Calcium Carbonate products at world-class quality to the markets of India, Bangladesh, Japan, Australia, Korea, Sri Lanka, and more.`,
        imageUrl: value_img2,
    },
    {
        title: 'Co-operative Company',
        description: `<p>At VIN, we believe that strong, collaborative relationships with our partners are the cornerstone of our success. As a leading supplier of high-quality Calcium Carbonate, we are committed to fostering long-term, mutually beneficial partnerships with businesses across various industries worldwide.</p>
                      <p>With a global network of partners and clients, we have been serving many businesses worldwide such as Bangladesh, Korea, Australia, Indonesia, Japan, and Sri Lanka, etc.  We also understand the importance of local market dynamics. Our team is equipped with the knowledge and experience to navigate diverse markets, ensuring that our solutions are perfectly suited to each region we operate in.</p>`,
        imageUrl: value_img3,
    },
]);


    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
      };
    
  return (




    <div>
        <div class="whoweare">
            <div class="_left">
                <h2 class="text-header">Who </h2>
                <h2 class="text-header">We Are? </h2>
            </div>
            <div class="_right">
            <p className="des">
              <strong style={{ color: '#00ADEF' }}>Vin Minerals Joint Stock Company</strong>  is proud of having many consecutive years as the best Calcium Carbonate supplier of Vietnam and becoming one of the leading companies in the areas of exploiting and exporting of CaCO3, we will be the best solution for your business. We provide optimal adhesive, whiteness, and mechanical strength solutions, helping your products stand out. It should be sustainable solutions for many industries. Our main business manufactures of Calcium Carbonate products, including untrafine calcium carbonate powder and stearic acid coated calcium carbonate powder with a D97 size of 2µm (D50 from 0.5 µm) or more, as an additive for paint, plastics, rubber, paper, pharmaceucials, cosmetics, aquaculture, animal feed, building materials in construction,etc.

            </p>
            <p className="des">
            Devoted to the Concept <strong>“people-oriented, customer first”</strong>, we focus on the production, processing, sales, and delivery of all kinds of Calcium Carbonate, with large-scale, standardized production of all kinds of marble, granite, quartzite, travertine, and limestone. From production and processing to sales and delivery, we make sure that all related-documents and certificates are in order to ensure the transaction process is carried out smoothly.
            </p>
            
                <div>
                <Slider {...settings}>
                    {sections.map((section, index) => (
                    <ImpactfulAIProductsSection
                        key={index}
                        title={section.title}
                        description={section.description}
                        imageUrl={section.imageUrl}
                    />
                    ))}
                </Slider>
                </div>
            </div>
        </div>
        
    </div>
    
    
  )
}

export default WhoWeAre