import React, { useState } from 'react';
import './Header.scss';
import logo from '../../assets/img/index/logo.png';

const Header = ({ HeaderContent, scrollPosition, onClickNav, toggleActive,  customClass }) => {
  const listCategory = HeaderContent.listCategory;
  const [activeMenuItem, setActiveMenuItem] = useState(null);

  const toggleDropdown = (index) => {
    setActiveMenuItem(index === activeMenuItem ? null : index);
  };

  return (
    <header id="header-main" className={`${scrollPosition > 60 ? 'shrink' : ''} ${customClass}`}>
      <div className={`header-main ${onClickNav ? 'is--active' : ''}`}>
        <div className="header-content">
          <div className="header-logo">
            <a href="/">
              <img src={logo} alt="" />
            </a>
          </div>
          <ul id="menu-main" className="menu-main">
            {listCategory.map((obj, index) => (
              <li key={`menu_${index}`} className="menu-item">
                <a
                  className="menu-link"
                  href={obj.link}
                  onClick={() => toggleDropdown(index)}
                >
                  {obj.name}{' '}
                  {obj.listSubItem.length !== 0 && (
                    <span className="icon-caret_down icon" style={{ marginLeft: `5px` }}></span>
                  )}
                </a>

                {obj.listSubItem.length !== 0 && (
                  <ul className={`sub-menu ${activeMenuItem === index ? 'show' : ''}`}>
                    {obj.listSubItem.map((value1, index1) => (
                      <li key={`sub_menu_${index1}`} className="menu-item">
                        <a className="sub-menu-link" href={value1[1]}>
                          {value1[0]}
                        </a>
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className={`header-navigation ${onClickNav ? 'is--active' : ''}`}>
        <div className="header-logo">
          <a href="/">
            <img src={logo} alt="" />
          </a>
        </div>
        <div className="pull" id="pull" onClick={toggleActive}>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
    </header>
  );
};

export default Header;
